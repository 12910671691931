<template>
  <div class="study-wrapper">
    <div class="intro-container">
      <div class="data-banner-wrapper">
        <h2 class="title">多场景解决方案，一站式助力主播成长</h2>
        <div class="desc">
          覆盖快手、抖音、视频号、小红书、拼多多、淘宝、天猫、京东等多个生态
        </div>
        <div class="data-box">
          <div
            v-for="(item, index) in introData"
            :key="index"
            class="data-item"
          >
            <div class="num-line">
              <count-to
                :ref="`count${index}`"
                :key="index"
                class="num"
                separator=""
                :startVal="0"
                :endVal="item.num"
                :duration="1200"
              ></count-to>
              <div v-if="item.unit" class="unit">{{ item.unit }}</div>
              <div v-if="item.plus" class="plus">+</div>
            </div>
            <div class="text-line">{{ item.desc }}</div>
          </div>
        </div>
      </div>
      <div class="go_form">
        <div class="btn">直播电商线下培训，星选会员限时免费！</div>
      </div>
      <div class="lecturer-box">
        <div class="title">魔筷讲师团队</div>
        <div
          class="swiper"
          @mouseenter="onSwiperEnter"
          @mouseleave="onSwiperLeave"
        >
          <div class="swiper-arrow prev"></div>
          <div class="swiper-arrow next"></div>
          <swiper ref="swiper" :options="lecturerSwiperOption">
            <swiper-slide
              v-for="(item, index) in lecturerSwiperData"
              :key="index"
            >
              <div class="card-item">
                <div class="mask"></div>
                <img :src="item.img" alt="" class="main" />
                <div class="titles-box">
                  <div class="name">@{{ item.name }}</div>
                  <pre class="titles">{{ item.titles }}</pre>
                </div>
                <div class="desc-box">
                  <div class="name">@{{ item.name }}</div>
                  <div class="desc">{{ item.desc }}</div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
    <div class="courses-container">
      <h2 class="title">专业成长课程</h2>
      <div class="points">
        <div
          v-for="(item, index) in coursesPointsData"
          :key="index"
          class="point-item"
        >
          <img :src="item.icon" alt="" class="icon" />
          <div class="title">{{ item.title }}</div>
          <pre class="desc">{{ item.desc }}</pre>
        </div>
      </div>
      <div class="courses">
        <div
          v-for="(item, index) in coursesData"
          :key="index"
          class="course-item"
        >
          <div class="poster" @click="showVideo(item)">
            <img :src="item.poster" alt="" class="main" />
            <img
              src="https://mktv-in-cdn.mockuai.com/16267470199756939.png"
              alt=""
              class="play-icon"
            />
          </div>
          <div class="info">
            <div class="top">
              <div class="speaker">
                <img :src="item.avatar" alt="" class="avatar" />
                <!-- <div class="avatar" :style="{backgroundImage: `url(${item.avatar})`}"></div> -->
                <div class="name">{{ item.speaker }}</div>
              </div>
              <div class="type">{{ item.type }}</div>
            </div>
            <div class="title">{{ item.name }}</div>
            <div class="desc">
              <div v-for="(d, i) in item.intro" :key="i" class="desc-item">
                {{ d }}
              </div>
            </div>
            <div class="btn" @click="showVideo(item)">观看视频</div>
          </div>
        </div>
      </div>
      <div class="more-box">
        <div class="text">
          短视频直播电商线下培训，价值<span class="text_color">2980</span
          >/人！星选会员限时免费！
        </div>
        <div class="go_form">立即报名</div>
      </div>
      <!-- <div class="more" @click="showQRCodeModal">查看更多课程</div> -->
    </div>
    <p-footer type="flow"></p-footer>
    <video-modal
      :visible="videoModalVisible"
      :src="videoSrc"
      @close="closeVideo"
    ></video-modal>
    <div v-show="qrcodeVisible" class="qrcode-modal">
      <div class="qrcode-box">
        <div class="text">扫一扫，获取更多专业课程</div>
        <img
          src="https://mktv-in-cdn.mockuai.com/16254722039507163.png"
          alt=""
          class="qrcode"
        />
        <img
          src="https://mktv-in-cdn.mockuai.com/16254731712693597.png"
          alt=""
          class="close-icon"
          @click="closeQRCodeModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CountTo from "vue-count-to";
import Footer from "@/layout/Footer.vue";
import VideoModal from "@/components/VideoModal.vue";

export default {
  components: {
    CountTo,
    "p-footer": Footer,
    VideoModal,
  },
  data() {
    return {
      videoSrc: "",
      videoModalVisible: false,
      qrcodeVisible: false,
      introData: [
        {
          num: 10,
          unit: "万",
          plus: true,
          desc: "累计服务次数",
        },
        {
          num: 10000,
          plus: true,
          desc: "累计孵化成功店主",
        },
        {
          num: 100,
          unit: "万",
          plus: true,
          desc: "覆盖学员人次",
        },
        {
          num: 50,
          unit: "家",
          plus: true,
          desc: "合作内容平台",
        },
      ],
      lecturerSwiperOption: {
        slidesPerView: 5,
        spaceBetween: "1.3888888888888888%",
        // observer: true,
        // observeParents: true,
        navigation: {
          nextEl: ".swiper-arrow.next",
          prevEl: ".swiper-arrow.prev",
        },
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        loop: true,
      },
      lecturerSwiperData: [
        {
          img: "https://mktv-in-cdn.mockuai.com/16254544331263066.png",
          name: "梵音",
          titles: `魔筷星选电商讲师\n腾讯看点、花椒、kk特邀电商讲师\n快手官方特邀授课讲师`,
          desc: "浙江省商务认证中心特邀授课讲师，10年互联网行业老兵，2年短视频-直播运营和培训经验；累计服务商家1W+，服务学员人次10W+",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16254544331242735.png",
          name: "大垚",
          titles: `快手电商合作讲师\n魔筷星选百城计划全国巡演首席讲师\n魔筷星选电商讲师`,
          desc: "7年互联网培训经验，擅长快手账号定位和启动期快速涨粉；曾受邀参与快手定期授课以及快手官方教程录制，单课时最高在线人数1.5W+",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16254544331172216.png",
          name: "安南",
          titles: `快手电商合作讲师\n腾讯视频红人集训营指导老师\n魔筷星选电商讲师`,
          desc: "具备直播带货全流程操盘能力，擅长短视频内容策划与数据化运营；曾受邀至快手官方授课分享，单课时在线人数最高1.2W+；累计授课时长超1200小时，服务学员人次20W+",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16254544331157347.png",
          name: "清酒",
          titles: `快手电商合作讲师\n腾讯视频红人集训营指导老师\n魔筷星选电商讲师`,
          desc: "具备直播带货全流程操盘能力，擅长短视频内容策划与数据化运营；曾受邀至快手官方授课分享，单课时在线人数最高1.2W+，服务学员人次20W+",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16254544331129279.png",
          name: "由钟",
          titles: `国家开放大学特聘讲师\n魔筷星选电商讲师\n浙江省商务认证中心特邀授课讲师`,
          desc: "5年互联网培训经验，2年短视频直播-社交电商实操培训经验；擅长短视频内容创作及短视频直播，孵化新人主播100+，服务学员人次10w+",
        },

        {
          img: "https://mktv-in-cdn.mockuai.com/16254544331021295.png",
          name: "万里",
          titles: `魔筷星选电商讲师\n浙江省商务认证中心特邀授课讲师\n百万社群实战导师`,
          desc: "6年社群团队打造与管理经验，擅长搭建社群运营体系与产品体系；有多年知识付费项目设计经验，策划线上线下培训营100+场，服务学员人次10w+",
        },
      ],
      coursesPointsData: [
        {
          icon: "https://mktv-in-cdn.mockuai.com/16254689857617976.png",
          title: "进阶培训",
          desc: `从0到1孵化账号，实战\n导师全方位课程指导`,
        },
        {
          icon: "https://mktv-in-cdn.mockuai.com/16254689857574510.png",
          title: "解决方案",
          desc: `全链路直播运营\n培训、货源、工具服务`,
        },
        {
          icon: "https://mktv-in-cdn.mockuai.com/16254689857542092.png",
          title: "优质链接",
          desc: `垂直行业爆款营销案例拆解学习\n链接精准流量`,
        },
        {
          icon: "https://mktv-in-cdn.mockuai.com/16254689857406600.png",
          title: "品牌赋能",
          desc: `6年短视频直播电商沉淀\n亿级直播爆品类目覆盖率`,
        },
      ],
      coursesData: [
        {
          video: "https://mktv-in-cdn.mockuai.com/16255544619429708.mp4",
          poster: "https://mktv-in-cdn.mockuai.com/16256431666585119.png",
          avatar: "https://mktv-in-cdn.mockuai.com/16254696491845585.png",
          speaker: "清酒",
          type: "To B 精选课",
          name: "直播电商团队搭建及管理",
          intro: [
            "· 直播电商岗位职责划分",
            "· 如何合理配置直播电商团队人员",
            "· 直播电商团队管理考核",
          ],
        },
        {
          video: "https://mktv-in-cdn.mockuai.com/16255544819566326.mp4",
          poster: "https://mktv-in-cdn.mockuai.com/16256431666539418.png",
          avatar: "https://mktv-in-cdn.mockuai.com/16254696491776696.png",
          speaker: "大垚",
          type: "小白速成课",
          name: "做好这3步，怕什么没流量",
          intro: [
            "· 这3个原因让作品远离热门",
            "· 什么内容会违规被限流",
            "· 热门助推器—快手粉条",
          ],
        },
        {
          video: "https://mktv-in-cdn.mockuai.com/16608220113383138.mp4",
          poster: "https://mktv-in-cdn.mockuai.com/16608220113462929.png",
          avatar: "https://mktv-in-cdn.mockuai.com/16608220113414853.png",
          speaker: "乾乾",
          type: "快手电商课",
          name: "直播卖货成交7步曲",
          intro: [
            "· 头部主播都在用的直播卖货流程",
            "· 怎样讲解让产品更有吸引力",
            "· 高效直播卖货流程拆解精学",
          ],
        },
        {
          video: "https://mktv-in-cdn.mockuai.com/16255550293565168.mp4",
          poster: "https://mktv-in-cdn.mockuai.com/16256431666565489.png",
          avatar: "https://mktv-in-cdn.mockuai.com/16254696491815303.png",
          speaker: "梵音",
          type: "To C 精选课",
          name: "视频号的4步精准定位拆解",
          intro: [
            "· 如何根据自己的定位找对标账号",
            "· 如何拆解对标账号进行模仿创作",
            "· 普通人快速创作5大案例拆解",
          ],
        },
        {
          video: "https://mktv-in-cdn.mockuai.com/16255550902941110.mp4",
          poster: "https://mktv-in-cdn.mockuai.com/16256431666519871.png",
          avatar: "https://mktv-in-cdn.mockuai.com/16254696491749632.png",
          speaker: "由钟",
          type: "行业认知课",
          name: "视频号生态基础认知",
          intro: [
            "· 为什么短视频这么火",
            "· 带你全方位了解视频号",
            "· 视频号创作者的成长之路",
          ],
        },
        {
          video: "https://mktv-in-cdn.mockuai.com/16255551653991706.mp4",
          poster: "https://mktv-in-cdn.mockuai.com/16256431665996957.png",
          avatar: "https://mktv-in-cdn.mockuai.com/16254696491638906.png",
          speaker: "万里",
          type: "视频号电商课",
          name: "视频号技巧和妙招",
          intro: [
            "· 短视频案例解读",
            "· 直播带货经典案例解读",
            "· 直播与社群高效联动案例解读",
          ],
        },
      ],
    };
  },
  computed: {
    swiper() {
      return this.$refs.swiper.$swiper;
    },
  },
  methods: {
    onSwiperEnter() {
      this.swiper.autoplay.stop();
    },
    onSwiperLeave() {
      this.swiper.autoplay.start();
    },
    showQRCodeModal() {
      this.qrcodeVisible = true;
    },
    closeQRCodeModal() {
      this.qrcodeVisible = false;
    },
    showVideo(item) {
      this.videoSrc = item.video;
      this.videoModalVisible = true;
    },
    closeVideo() {
      this.videoModalVisible = false;
    },
    jumpCollect(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../style/common.less";

.study-wrapper {
  .intro-container {
    position: relative;
    padding-bottom: 20.833333333333332vw;
    background-color: #fff;

    .data-banner-wrapper {
      box-sizing: border-box;
      height: 37.479166666666664vw;
      padding-top: 5vw;
      background-image: url(https://mktv-in-cdn.mockuai.com/16253074489026573.png);
      background-size: cover;
      background-repeat: no-repeat;

      h2.title {
        margin: 0 auto;
        text-align: center;
        line-height: 1.3888888888888888;
        font-size: 1.875vw;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
      > .desc {
        margin-bottom: 2.5520833333333335vw;
        text-align: center;
        line-height: 1.3888888888888888;
        font-size: 0.9375vw;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
      .data-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 23.4375vw;
        .data-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          .num-line {
            display: flex;
            align-items: flex-end;
            color: #fff;
            .num {
              line-height: 3.5416666666666665vw;
              font-size: 3.125vw;
              font-family: DINCond-Light, DINCond;
              font-weight: 300;
              color: #ffffff;
            }
            .unit,
            .plus {
              line-height: 1.4;
              font-size: 1.0416666666666667vw;
              font-family: PingFangSC-Thin, PingFang SC;
              font-weight: 100;
            }
            .unit {
              margin-bottom: 0.5208333333333334vw;
            }
            .plus {
              align-self: center;
            }
          }
          .text-line {
            line-height: 1.3888888888888888;
            font-size: 0.9375vw;
            font-size: 18px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: #fff;
            opacity: 0.8;
          }
        }
      }
    }
    .go_form {
      position: absolute;
      left: 50%;
      top: 35%;
      transform: translateX(-50%);
      .pxToVw(width, 496);
      .pxToVw(height, 60);
      .pxToVw(border-radius, 30);
      .pxToVw(line-height, 60);
      background: #ff6600;
      text-align: center;
      margin: 0 auto;
      cursor: pointer;
      .btn {
        .pxToVw(font-size, 24);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .lecturer-box {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 75vw;

      .title {
        .pxToVw(margin-top, 62);
        line-height: 1.3888888888888888;
        text-align: center;
        font-size: 1.875vw;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ff6600;
      }
      .swiper {
        position: relative;
        margin: 2.7083333333333335vw auto 0;
        /deep/ .swiper-wrapper {
          height: 27.083333333333332vw;
        }
        .swiper-arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 2.2916666666666665vw;
          height: 2.2916666666666665vw;
          background-size: cover;
          background-repeat: no-repeat;
          cursor: pointer;
          &.prev {
            left: -2.2916666666666665vw;
            background-image: url(https://mktv-in-cdn.mockuai.com/16252291642899934.png);
          }
          &.next {
            right: -2.2916666666666665vw;
            background-image: url(https://mktv-in-cdn.mockuai.com/16252259933873886.png);
          }
        }
        .card-item {
          box-sizing: border-box;
          position: relative;
          width: 14.166666666666666vw;
          height: 100%;
          border-radius: 0.8333333333333334vw;
          overflow: hidden;
          cursor: auto;
          &:hover {
            .mask {
              background-color: rgba(0, 9, 42, 0.56);
            }
            .titles-box {
              visibility: hidden;
            }
            .desc-box {
              visibility: visible;
            }
          }
          .mask {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 0.8333333333333334vw;
            background: linear-gradient(
              180deg,
              rgba(0, 9, 42, 0) 0%,
              rgba(0, 9, 42, 0.77) 100%
            );
          }
          .main {
            width: 100%;
          }
          .titles-box {
            position: absolute;
            bottom: 0;
            left: 0;
            padding-left: 1.0416666666666667vw;
            padding-bottom: 0.8854166666666666vw;
            color: #fff;
            .name {
              margin-bottom: 0.20833333333333334vw;
              line-height: 1.4;
              font-size: 1.0416666666666667vw;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
            }
            .titles {
              margin: 0;
              line-height: 1.4;
              font-size: 0.7291666666666666vw;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              opacity: 0.8;
            }
          }
          .desc-box {
            visibility: hidden;
            position: absolute;
            // top: 10.416666666666666vw;
            .pxToVw(bottom, 20);
            left: 0;
            padding: 0 1.0416666666666667vw;
            color: #fff;
            .name {
              margin-bottom: 0.7291666666666666vw;
              font-size: 1.0416666666666667vw;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
              line-height: 1.4;
              text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
            }
            .desc {
              font-size: 0.7291666666666666vw;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: #ffffff;
              line-height: 1.4;
              text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
  .courses-container {
    padding-top: 4.895833333333333vw;
    .pxToVw(padding-bottom, 76);
    > h2.title {
      margin: 0;
      margin-bottom: 2.0833333333333335vw;
      text-align: center;
      line-height: 1.3888888888888888;
      font-size: 1.875vw;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ff6600;
    }
    .points {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 3.0208333333333335vw;
      .point-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        .icon {
          width: 4.166666666666667vw;
          height: 4.166666666666667vw;
          margin-right: 0.20833333333333334vw;
        }
        .title {
          margin-bottom: 0.15625vw;
          font-size: 1.25vw;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333;
        }
        .desc {
          text-align: center;
          line-height: 1.4;
          font-size: 0.7291666666666666vw;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
        &:nth-child(1) {
          margin-right: 7.03125vw;
        }
        &:nth-child(2) {
          margin-right: 6.40625vw;
        }
        &:nth-child(3) {
          margin-right: 6.770833333333333vw;
        }
      }
    }
    .courses {
      display: flex;
      flex-wrap: wrap;
      width: 75vw;
      margin: 0 auto;
      .course-item {
        box-sizing: border-box;
        width: 21.875vw;
        .pxToVw(height, 535);
        margin-right: 4.6875vw;
        margin-bottom: 5.208333333333333vw;
        border-radius: 0.4166666666666667vw;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        &:nth-child(3n) {
          margin-right: 0;
        }
        &:nth-last-child(-n + 3) {
          margin-bottom: 0;
        }
        &:hover {
          box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);
          // border: 1px solid #ddd;
        }
        .poster {
          box-sizing: border-box;
          position: relative;
          .pxToVw(width, 420);
          .pxToVw(height, 236);
          cursor: pointer;
          .main {
            width: 100%;
          }
          .play-icon {
            visibility: hidden;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .pxToVw(width, 60);
            .pxToVw(height, 60);
          }
          &:hover {
            .play-icon {
              visibility: visible;
            }
          }
        }
        .info {
          box-sizing: border-box;
          padding: 1.0416666666666667vw;
          .top {
            display: flex;
            align-items: center;
            margin-bottom: 0.7291666666666666vw;
            .speaker {
              display: flex;
              align-items: center;
              .avatar {
                width: 2.5vw;
                height: 2.5vw;
                margin-right: 0.5208333333333334vw;
                border-radius: 50%;
                object-fit: cover;
                // background-size: cover;
                // background-repeat: no-repeat
              }
              .name {
                font-size: 0.9375vw;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
              }
            }
            .type {
              flex: 1;
              text-align: right;
              font-size: 0.9375vw;
              font-family: HYYakuHei-EEW, HYYakuHei;
              font-weight: normal;
              color: #ff6600;
            }
          }
          .title {
            margin-bottom: 0.5208333333333334vw;
            line-height: 1.6666666666666667;
            font-size: 0.9375vw;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
          .desc {
            .desc-item {
              line-height: 1.5625vw;
              font-size: 0.7291666666666666vw;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            }
          }
          .btn {
            height: 2.0833333333333335vw;
            line-height: 2.0833333333333335vw;
            text-align: center;
            margin-top: 1.0416666666666667vw;
            border-radius: 0.20833333333333334vw;
            border: 1px solid #ff6600;
            font-size: 0.9375vw;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ff6600;
            cursor: pointer;
            &:hover {
              font-weight: 600;
            }
          }
        }
      }
    }
    .more {
      width: 12.5vw;
      height: 3.125vw;
      line-height: 3.125vw;
      text-align: center;
      margin: 3.125vw auto 0;
      background: #ff6600;
      border-radius: 1.5625vw;
      font-size: 1.25vw;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
      &:hover {
        box-shadow: 0px 7px 21px 0px rgba(255, 102, 0, 0.4);
      }
    }
    .more-box {
      margin: 60 * @unitVw auto 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .qrcode-box {
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
      .text_color {
        color: #ff6600;
      }
      .text {
        .pxToVw(line-height, 33);
        .pxToVw(height, 98);
        .pxToVw(font-size, 24);
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
      .go_form {
        .pxToVw(width, 220);
        .pxToVw(height, 60);
        .pxToVw(border-radius, 30);
        .pxToVw(font-size, 24);
        .pxToVw(line-height, 60);
        text-align: center;
        background: #ff6600;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}
.qrcode-modal {
  position: fixed;
  z-index: 20000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  .qrcode-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    .text {
      margin-bottom: 16px;
      line-height: 33px;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
    .qrcode {
      width: 260px;
      height: 260px;
      margin-bottom: 60px;
    }
    .close-icon {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }
}
</style>
