<template>
  <div>
    <tools-bar ref="toolsBar" @backtop="onBacktop"></tools-bar>
    <consult-phone ref="consultPhone"></consult-phone>
    <consult-modal ref="consultModal" @phoneConsult="showConsultPhone"></consult-modal>
    <p-header type="flow" ref="header"></p-header>
    <div id="fullpage" ref="fullpage" @click="onContainerClick">
      <div class="hide" ref='box'> 
        <div class="section">
          <video-section></video-section>
        </div>
        <div class="section">
          <panels-section></panels-section>
        </div>
        <div class="section" ref="dataSection">
          <data-section ref="dataComp"></data-section>
        </div>
        <div class="section">
          <anchor-section ref="anchorComp"></anchor-section>
          <join-section></join-section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { decode } from 'js-base64';
import Header from '../layout/Header.vue'
import ToolsBar from '@/components/ToolsBar.vue'
import ConsultPhone from '@/components/ConsultPhone.vue'
import ConsultModal from '@/components/ConsultModal.vue'
import VideoSection from '@/components/home/VideoSection.vue'
import PanelsSection from '@/components/home/PanelsSection.vue'
import DataSection from '@/components/home/DataSection.vue'
import AnchorSection from '@/components/home/AnchorSection.vue'
// import StoreSection from '@/components/home/StoreSection.vue'
import JoinSection from '@/components/home/JoinSection.vue'

export default {
  name: "Home",
  head: {
    title: '零售-品牌集团-供应链-主播带货-魔筷科技',
    meta: [
      {
        name: 'Keywords',
        content: '零售,主播达人带货,品牌集团,供应链,创始人王玉林,魔筷科技'
      },
      {
        name: 'Description',
        content: '【魔筷科技-全球化品牌集团】为全球消费者提供多个领域的优质产品，产销一体，推动产业整合与升级，为消费者带来健康、美好的生活体验。'
      }
    ]
  },
  components: {
    'p-header': Header,
    ToolsBar,
    ConsultPhone,
    ConsultModal,
    VideoSection,
    PanelsSection,
    DataSection,
    AnchorSection,
    // StoreSection,
    JoinSection
  },
  beforeMount() {
    this.$bus.$emit('headerModeChange', 'transparent')
  },
  mounted() {
    localStorage.removeItem('currentSection')
    this.$refs.toolsBar.hide()
    this.initFullPage()
  },
  destroyed() {
    window.fullpage_api.destroy()
  },
  methods: {
    initFullPage() {
      require('fullpage.js/vendors/scrolloverflow.min.js')
      const Fullpage = require("fullpage.js");
      this.$refs.box.classList.remove('hide')
      new Fullpage("#fullpage", {
        licenseKey: decode('4D11C3F5-EC4A4533-AD657D67-11F02983'),
        verticalCentered: false,
        autoScrolling: true,
        scrollOverflow: true,
        fixedElements: '#page-header, #video-modal',
        afterRender: () => {
          setTimeout(() => {
            console.log('弹窗')
            this.showModal()
          }, 10000)
          // setTimeout(() => {
          //   window.fullpage_api.setAutoScrolling(true)
          // }, 2000)
        },
        onLeave: (origin, dest, dir) => {
          console.log(origin, dest, dir);
          localStorage.setItem('currentSection', dest.index)
          if (dest.index !== 0) {
            this.$refs.toolsBar.show()
          } else {
            this.$refs.toolsBar.hide()
          }
          if (dest.index === 2) {
            this.$refs.dataComp.reloadCount()
          }
          if (dest.index === 3) {
            this.$refs.anchorComp.reloadCount()
          }
          if (dest.isFirst) {
            this.$bus.$emit('headerModeChange', 'transparent')
          } else if ([2,4,5].includes(dest.index)) {
            this.$bus.$emit('headerModeChange', 'dark')
          }else {
            this.$bus.$emit('headerModeChange', 'normal')
          }
          console.log('scroll', this.$refs.consultModal.visible)
          if (this.$refs.consultModal.visible) {
            setTimeout(() => {
              this.closeModal()
            }, 5000)
          }
        }
      });
    },
    showModal() {
      console.log(this.$refs.consultModal)
      this.$refs.consultModal.show()
    },
    closeModal() {
      this.$refs.consultModal.close()
    },
    onContainerClick() {
      this.closeConsultPhone()
    },
    showConsultPhone() {
      this.$refs.consultPhone.show()
    },
    closeConsultPhone() {
      this.$refs.consultPhone.close()
    },
    onBacktop() {
      window.fullpage_api.moveTo(1);
    }
    // onPanelChange(index) {
    //   let childNodes = [...this.$refs.panels.children]
    //   // console.log(childNodes, index)
    //   childNodes.forEach((node, i) => {
    //     if (i === index ) {
    //       if (!node.classList.contains('active')) {
    //         node.classList.add('active')
    //       }
    //     } else {
    //       node.classList.remove('active')
    //     }
    //   })
    // },
    // onDataIndexChange(e) {
    //   this.$nextTick(() => {
    //     console.log(e, this.$refs, this.$refs.dataSection)
    //     const dataDom = this.$refs.dataSection
    //     console.log(dataDom)
    //     dataDom.classList.add(e.class)
    //   })
    // }
  }
};
</script>

<style lang="less" scoped>
.hide {
  display: none;
}
.section {
  position: relative;
  &:nth-child(1) {
    // background-color: rgba(0, 9, 42, .3);
    background-color: transparent;
    
  }
  &:nth-child(2) {
    background-color: #fff;
  }
  &:nth-child(3) {
    background-color: #00092a;
  }
  &:nth-child(4) {
    background-color: #fff;
  }
  &:nth-child(5) {
    background-color: rgba(0, 9, 42, 1);
  }
  &:nth-child(6) {
    // height: 200vh!important;
  }

  &.wrap-saas {
    background-image: url(https://mktv-in-cdn.mockuai.com/16238093980913802.png);
  }
  &.wrap-supply {
    background-image: url(https://mktv-in-cdn.mockuai.com/16238152069838910.png);
  }
  &.wrap-anchor {
    background-image: url(https://mktv-in-cdn.mockuai.com/16238149059689781.png);
  }
  &.wrap-data {
    background-image: url(https://mktv-in-cdn.mockuai.com/16238154825288660.png);
  }
}
.pt-90 {
  padding-top: 90px; 
}
</style>
