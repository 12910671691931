<template>
  <section-container :bgClass="wrapClass" ref="sectionContainer">
    <div class="data-wrapper">
      <h2 class="title">直播电商综合解决方案</h2>
      <h5 class="subtitle">
        覆盖快手、抖音、视频号、小红书、拼多多、淘宝、天猫、京东等多个生态
      </h5>
      <div class="content-box" :class="boxClass" ref="contentBox">
        <div class="content-bg" :class="boxClass" ref="contentBg"></div>
        <div class="menu">
          <div
            v-for="(item, index) in dataPanels"
            :key="index"
            class="menu-item"
            :class="currentIndex === index ? 'active' : ''"
            @mouseenter="onMenuChange(index)"
          >
            <img :data-src="item.icon" alt="" class="icon" />
            <div class="text">{{ item.title }}</div>
          </div>
        </div>
        <div class="data-box" ref="dataBox" :data-index="currentIndex">
          <div class="desc">{{ dataDesc }}</div>
          <div class="data-items">
            <div
              v-for="(item, index) in displayData"
              :key="item.id"
              class="data-item"
              :style="{ width: `${(140 * item.w) / 1640}vw` }"
            >
              <div class="num-line">
                <count-to
                  :ref="`count${index}`"
                  :key="index"
                  class="num"
                  separator=""
                  :startVal="0"
                  :endVal="item.num"
                  :duration="1200"
                  :autoplay="false"
                ></count-to>
                <!-- <span class="num">{{item.num}}</span> -->
                <span class="unit">{{ item.unit }}</span>
                <span v-if="item.plus" class="plus">+</span>
              </div>
              <div class="text-line">
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>
        <!-- <img ref="banner" :src="banner" alt="" class="banner" :class="bannerClass"> -->
      </div>
    </div>
  </section-container>
</template>

<script>
import CountTo from 'vue-count-to'
import SectionContainer from '@/components/home/SectionContainer.vue'

export default {
  components: {
    CountTo,
    SectionContainer,
  },
  data() {
    return {
      wrapClass: 'wrap-saas',
      currentIndex: 0,
      dataPanels: [
        {
          icon: 'https://mktv-in-cdn.mockuai.com/16274583440347739.png',
          title: `直播电商场景化\nSaaS工具`,
          banner: 'https://mktv-in-cdn.mockuai.com/16238093980864188.png',
          bannerClass: 'banner-saas',
          contentClass: 'bg-saas',
          wrapClass: 'wrap-saas',
          dataDesc: '稳定高效的卖货和运营工具',
          data: [
            {
              id: 0,
              num: 100,
              unit: '万',
              text: '用户同时进入',
              w: 120,
            },
            {
              id: 1,
              num: 10,
              unit: '万',
              text: '用户同时下单',
              w: 120,
            },
            {
              id: 2,
              num: 10,
              unit: '万',
              text: '消息同时发送',
              w: 120,
            },
          ],
        },
        {
          icon: 'https://mktv-in-cdn.mockuai.com/16274583440287418.png',
          title: `智慧高效的\n供应链赋能体系`,
          banner: 'https://mktv-in-cdn.mockuai.com/16238152069543524.png',
          bannerClass: 'banner-supply',
          contentClass: 'bg-supply',
          wrapClass: 'wrap-supply',
          dataDesc: '强大可靠的供应链服务能力',
          data: [
            {
              id: 3,
              num: 100,
              unit: '万',
              text: '精选sku',
              plus: true,
              w: 103,
            },
            {
              id: 4,
              num: 95,
              unit: '%',
              text: '直播热销商品覆盖率',
              w: 180,
            },
            {
              id: 5,
              num: 90,
              unit: '%',
              text: '类目覆盖率',
              w: 100,
            },
          ],
        },
        {
          icon: 'https://mktv-in-cdn.mockuai.com/16274583440238524.png',
          title: `全链路\n直播运营服务`,
          banner: 'https://mktv-in-cdn.mockuai.com/16238149059424872.png',
          bannerClass: 'banner-anchor',
          contentClass: 'bg-anchor',
          wrapClass: 'wrap-anchor',
          dataDesc: '主播卖货贴心大管家',
          data: [
            {
              id: 6,
              num: 10,
              unit: '万',
              text: '累计服务专场次数',
              plus: true,
              w: 160,
            },
            {
              id: 7,
              num: 5000,
              unit: '',
              text: '金牌认证合作讲师',
              plus: true,
              w: 160,
            },
            {
              id: 8,
              num: 100,
              unit: '',
              text: '过亿GMV活动案例',
              plus: true,
              w: 166,
            },
          ],
        },
        {
          icon: 'https://mktv-in-cdn.mockuai.com/16274583440157722.png',
          title: `直播电商\n大数据应用`,
          banner: 'https://mktv-in-cdn.mockuai.com/16238154825046067.png',
          bannerClass: 'banner-data',
          contentClass: 'bg-data',
          wrapClass: 'wrap-data',
          dataDesc: '助力全面提升直播效率',
          data: [
            {
              id: 9,
              num: 95,
              unit: '%',
              text: '"人-货"精准匹配率',
              w: 172,
            },
            {
              id: 10,
              num: 30,
              unit: '%',
              text: '卖货转化率平均提高',
              w: 180,
            },
            {
              id: 11,
              num: 300,
              unit: '万',
              text: '主播都在使用',
              plus: true,
              w: 120,
            },
          ],
        },
      ],
    }
  },
  computed: {
    // wrapClass() {
    //   return this.dataPanels[this.currentIndex].wrapClass
    // },
    boxClass() {
      return this.dataPanels[this.currentIndex].contentClass
    },
    banner() {
      return this.dataPanels[this.currentIndex].banner
    },
    bannerClass() {
      return this.dataPanels[this.currentIndex].bannerClass
    },
    dataDesc() {
      return this.dataPanels[this.currentIndex].dataDesc
    },
    displayData() {
      return this.dataPanels[this.currentIndex].data
    },
  },
  methods: {
    reloadCount() {
      this.displayData.forEach((item, index) => {
        console.log(this.$refs[`count${index}`], item, index)
        this.$refs[`count${index}`][0].start()
      })
    },
    onMenuChange(index) {
      if (this.currentIndex === index) {
        return
      }
      this.currentIndex = index
      this.$nextTick(() => {
        this.reloadCount()
      })

      this.$refs.dataBox.classList.remove('fade-in-animation')
      // this.$refs.banner.classList.remove('fade-in')
      this.$refs.contentBg.classList.remove('fade-in')
      // this.$refs.contentBox.classList.remove('media-fade-in')
      window.requestAnimationFrame(() => {
        window.requestAnimationFrame(() => {
          this.$refs.dataBox.classList.add('fade-in-animation')
          // this.$refs.banner.classList.add('fade-in')
          this.$refs.contentBg.classList.add('fade-in')
          // this.$refs.contentBox.classList.add('media-fade-in')
        })
      })

      if (window._czc) {
        window._czc.push([
          '_trackEvent',
          '首页第三屏数据板块左侧tab',
          '鼠标移入切换',
          this.dataPanels[index].title,
        ])
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '../../style/common.less';

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes media-fade-in {
  0% {
    transform: scale(1.05);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes fade-in-animation {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.fade-in {
  animation: fade-in 1s ease 0s 1 normal both;
}
.media-fade-in {
  animation: media-fade-in 0.8s ease 0s 1 normal both;
}
.fade-in-animation {
  animation: fade-in-animation 0.4s ease 80ms 1 normal both;
}
.data-wrapper {
  box-sizing: border-box;
  height: 100%;
  padding: 0 6.097560975609756%;
  background-size: cover;
  background-repeat: no-repeat;
  &.wrap-saas {
    background-image: url(https://mktv-in-cdn.mockuai.com/16286534913535319.png);
  }
  &.wrap-supply {
    background-image: url(https://mktv-in-cdn.mockuai.com/16238152069838910.png);
  }
  &.wrap-anchor {
    background-image: url(https://mktv-in-cdn.mockuai.com/16238149059689781.png);
  }
  &.wrap-data {
    background-image: url(https://mktv-in-cdn.mockuai.com/16238154825288660.png);
  }
}
h2.title {
  text-align: center;
  line-height: 1.4;
  padding-top: 4.375%;
  margin-bottom: 0.6097560975609756%;
  // font-size: 36px;
  font-size: 2.1951219512195124vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #fff;
}
h5.subtitle {
  margin-bottom: 1.3414634146341464%;
  text-align: center;
  line-height: 1.4;
  // font-size: 18px;
  font-size: 1.0975609756097562vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fff;
  opacity: 0.8;
}
.content-box {
  position: relative;
  height: 69.1358024691358%;
  border-radius: 16px;
  overflow: hidden;
  background-color: #001357;
  display: flex;

  .content-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    &.bg-saas {
      // background-image: url(https://mktv-in-cdn.mockuai.com/16238093980881335.png);
      background-image: url(https://mktv-in-cdn.mockuai.com/16277238664112404.png);
    }
    &.bg-supply {
      // background-image: url(https://mktv-in-cdn.mockuai.com/16238152069794068.png);
      background-image: url(https://mktv-in-cdn.mockuai.com/16309805239156737.png);
    }
    &.bg-anchor {
      // background-image: url(https://mktv-in-cdn.mockuai.com/16238149059657967.png);
      background-image: url(https://mktv-in-cdn.mockuai.com/16277240252572093.png);
    }
    &.bg-data {
      // background-image: url(https://mktv-in-cdn.mockuai.com/16238154825264175.png);
      background-image: url(https://mktv-in-cdn.mockuai.com/16277247505809411.png);
    }
  }
  .menu {
    width: 13.88888888888889%;
    .menu-item {
      position: relative;
      box-sizing: border-box;
      height: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background-color: rgba(0, 9, 42, 0.1);
      // cursor: pointer;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.2);
      }
      &:last-child {
        &::after {
          content: '';
          width: 0;
          height: 0;
        }
      }
      &.active {
        background-color: rgba(255, 102, 0, 0.7);
        border-radius: 0;
        &:first-child {
          border-top-left-radius: 8px;
        }
        &:last-child {
          border-bottom-left-radius: 8px;
        }
        &::after {
          content: '';
          width: 0;
          height: 0;
        }
      }
      .icon {
        // width: 16%;
        // height: 22.857142857142858%;
        // margin-bottom: 7%;
        .pxToVw(width, 48, 1640);
        .pxToVw(height, 48, 1640);
        .pxToVw(margin-bottom, 6, 1640);
      }
      .text {
        text-align: center;
        white-space: pre-wrap;
        line-height: 1.4;
        font-family: PingFangSC-Regular, PingFang SC;
        // font-size: 18px;
        font-size: 1.0975609756097562vw;
        font-weight: 400;
        color: #fff;
      }
    }
  }
  .data-box {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    margin-left: 4.027777777777778%;
    padding-top: 9.207317073170731vw;
    color: #fff;

    .desc {
      // margin-bottom: 13.035714285714286%;
      margin-bottom: 4.451219512195122vw;
      font-family: PingFangSC-Semibold, PingFang SC;
      // font-size: 30px;
      font-size: 1.829268292682927vw;
      font-weight: 600;
      animation: fade-in-animation 0.4s ease 80ms 1 normal both;
    }
    .data-items {
      display: flex;
      align-items: center;
      animation: fade-in-animation 0.4s ease 80ms 1 normal both;
      .data-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 2.4390243902439024vw;
        font-size: 1.0416667vw;
        font-weight: 400;
        &:last-child {
          margin-right: 0;
        }
        .num-line {
          display: flex;
          align-items: flex-end;
          .num {
            line-height: 1.1470588235294117;
            font-size: 4.146341463414634vw;
            font-family: DINCond-Light, DINCond;
            font-weight: 300;
          }
          .unit,
          .plus {
            line-height: 1.4;
            // font-size: 20px;
            font-size: 1.2195121951219512vw;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 100;
          }
          .unit {
            margin-bottom: 0.6097560975609756vw;
          }
          .plus {
            font-weight: 100;
            align-self: center;
          }
        }
        .text-line {
          line-height: 1.4;
          font-size: 1.2195121951219512vw;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          opacity: 0.8;
        }
      }
    }
  }
  .banner {
    position: absolute;
    // object-fit: cover;
    &.banner-saas {
      right: 3.3333333333333335%;
      bottom: 0;
      width: 39.166666666666664%;
    }
    &.banner-supply {
      right: 3.1944444444444446%;
      bottom: 8.392857142857142%;
      width: 40.06944444444444%;
    }
    &.banner-anchor {
      right: 2.8472222222222223%;
      bottom: 8.03571428571428%;
      width: 37.22222222222222%;
    }
    &.banner-data {
      right: 3.0555555555555554%;
      bottom: 0;
      width: 33.611111111111114%;
    }
  }
}
@media screen and (min-width: 1640px) {
  h2.title {
    padding-top: 63px;
    margin-bottom: 10px;
    font-size: 36px;
  }
  h5.subtitle {
    margin-bottom: 22px;
    font-size: 18px;
  }
  .content-box {
    &.bg-saas {
      .data-box {
        .data-items {
          .data-item {
            margin-right: 40px;
            width: 120px !important;
          }
        }
      }
    }
    &.bg-supply {
      .data-box {
        .data-items {
          .data-item {
            margin-right: 40px;
            &:nth-child(1) {
              width: 103px !important;
            }
            &:nth-child(2) {
              width: 180px !important;
            }
            &:nth-child(3) {
              width: 100px !important;
            }
          }
        }
      }
    }
    &.bg-anchor {
      .data-box {
        .data-items {
          .data-item {
            margin-right: 40px;
            &:nth-child(1) {
              width: 160px !important;
            }
            &:nth-child(2) {
              width: 160px !important;
            }
            &:nth-child(3) {
              width: 166px !important;
            }
          }
        }
      }
    }
    &.bg-data {
      .data-box {
        .data-items {
          .data-item {
            margin-right: 40px;
            &:nth-child(1) {
              width: 172px !important;
            }
            &:nth-child(2) {
              width: 180px !important;
            }
            &:nth-child(3) {
              width: 120px !important;
            }
          }
        }
      }
    }
    .menu {
      .menu-item {
        .text {
          font-size: 18px;
        }
      }
    }
    .data-box {
      padding-top: 151px;
      .desc {
        margin-bottom: 73px;
        font-size: 30px;
      }
      .data-items {
        .data-item {
          .num-line {
            .num {
              font-size: 68px;
            }
            .unit,
            .plus {
              font-size: 20px;
            }
            .unit {
              margin-bottom: 10px;
            }
          }
          .text-line {
            font-size: 20px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 960px) {
  h2.title {
    padding-top: 36.875px;
    margin-bottom: 5.125px;
    font-size: 21.0732px;
  }
  h5.subtitle {
    margin-bottom: 11.2969px;
    font-size: 10.5366px;
  }
  .content-box {
    &.bg-saas {
      .data-box {
        .data-items {
          .data-item {
            margin-right: 23.4146px;
            width: 70.234px !important;
          }
        }
      }
    }
    &.bg-supply {
      .data-box {
        .data-items {
          .data-item {
            margin-right: 23.4146px;
            &:nth-child(1) {
              width: 60.281px !important;
            }
            &:nth-child(2) {
              width: 105.359px !important;
            }
            &:nth-child(3) {
              width: 58.531px !important;
            }
          }
        }
      }
    }
    &.bg-anchor {
      .data-box {
        .data-items {
          .data-item {
            margin-right: 23.4146px;
            &:nth-child(1) {
              width: 93.656px !important;
            }
            &:nth-child(2) {
              width: 93.656px !important;
            }
            &:nth-child(3) {
              width: 97.156px !important;
            }
          }
        }
      }
    }
    &.bg-data {
      .data-box {
        .data-items {
          .data-item {
            margin-right: 23.4146px;
            &:nth-child(1) {
              width: 100.672px !important;
            }
            &:nth-child(2) {
              width: 105.359px !important;
            }
            &:nth-child(3) {
              width: 70.234px !important;
            }
          }
        }
      }
    }
    .menu {
      .menu-item {
        .text {
          font-size: 10.5366px;
        }
      }
    }
    .data-box {
      padding-top: 88.3902px;
      .desc {
        margin-bottom: 42.732px;
        font-size: 17.561px;
      }
      .data-items {
        .data-item {
          .num-line {
            .num {
              font-size: 39.8049px;
            }
            .unit,
            .plus {
              font-size: 11.7073px;
            }
            .unit {
              margin-bottom: 5.85366px;
            }
          }
          .text-line {
            font-size: 11.7073px;
          }
        }
      }
    }
  }
}
</style>
