<template>
  <section-container>
    <div class="panels-wrapper">
      <h2 class="title">直播选货 就用魔筷</h2>
      <h5 class="subtitle">为主播赋能</h5>
      <div class="panels" ref="panels">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="panel-item"
          :class="currentIndex === index ? 'active' : ''"
          @mouseover="onPanelChange(index)"
        >
          <div class="role">{{ item.role }}</div>
          <transition name="fade">
            <div v-show="currentIndex === index" class="content">
              <div class="title">{{ item.title }}</div>
              <div class="functions">
                <div
                  v-for="(f, i) in item.functions"
                  :key="i"
                  class="function-item"
                >
                  <img :data-src="f.icon" alt="" class="icon" />
                  <div class="title">{{ f.title }}</div>
                  <div class="desc">{{ f.desc }}</div>
                </div>
              </div>
              <a :href="item.link" class="more" @click="onMoreClick(item)"
                >了解更多</a
              >
            </div>
          </transition>
        </div>
      </div>
    </div>
  </section-container>
</template>

<script>
import SectionContainer from "@/components/home/SectionContainer.vue";
export default {
  components: {
    SectionContainer,
  },
  data() {
    return {
      currentIndex: 0,
      items: [
        {
          role: "主播",
          link: "/anchor",
          title: "主播开店工具，让直播卖货事半功倍",
          bg: "https://mktv-in-cdn.mockuai.com/16237607055768282.png",
          activeBg: "https://mktv-in-cdn.mockuai.com/16237605594638747.png",
          functions: [
            {
              icon: "https://mktv-in-cdn.mockuai.com/16250180606188227.png",
              iconw: 44,
              iconh: 45,
              title: "资源管理",
              desc: "货源匹配、商品素材提炼、线上选品会",
            },
            {
              icon: "https://mktv-in-cdn.mockuai.com/16250180606151782.png",
              iconw: 47,
              iconh: 43,
              title: "店铺管理",
              desc: "详情设置、订单管理、交易设置",
            },
            {
              icon: "https://mktv-in-cdn.mockuai.com/16250180606105752.png",
              iconw: 42,
              iconh: 47,
              title: "客户管理",
              desc: "私域粉丝人工智能运营工具",
            },
            {
              icon: "https://mktv-in-cdn.mockuai.com/16250180606067642.png",
              iconw: 46,
              iconh: 46,
              title: "数据管理",
              desc: "直播间数据追踪、粉丝标签分析、销售数据管理",
            },
            {
              icon: "https://mktv-in-cdn.mockuai.com/16250180606049607.png",
              iconw: 47,
              iconh: 46,
              title: "营销管理",
              desc: "优惠券、拼团、满件减、限时折扣",
            },
            {
              icon: "https://mktv-in-cdn.mockuai.com/16250180605959739.png",
              iconw: 41,
              iconh: 47,
              title: "培训与成长",
              desc: "新人成长，主播圈子",
            },
          ],
        },
        // {
        //   role: "商家",
        //   link: "/store",
        //   title: "触达多平台主播直播卖货，助力商家成长",
        //   bg: "https://mktv-in-cdn.mockuai.com/16237607418287684.png",
        //   activeBg: "https://mktv-in-cdn.mockuai.com/16237606426209129.png",
        //   functions: [
        //     {
        //       icon: "https://mktv-in-cdn.mockuai.com/16250184562868653.png",
        //       iconw: 36,
        //       iconh: 46,
        //       title: "直连主播",
        //       desc: "专属推荐、多端推送、多元展示位",
        //     },
        //     {
        //       icon: "https://mktv-in-cdn.mockuai.com/16250184562839116.png",
        //       iconw: 50,
        //       iconh: 40,
        //       title: "物流管理",
        //       desc: "云仓代发、成本优化、开放式ERP",
        //     },
        //     {
        //       icon: "https://mktv-in-cdn.mockuai.com/16250184562803516.png",
        //       iconw: 47,
        //       iconh: 46,
        //       title: "售后管理",
        //       desc: "1000+团队，24小时全天侯服务",
        //     },
        //     {
        //       icon: "https://mktv-in-cdn.mockuai.com/16250184562778418.png",
        //       iconw: 47,
        //       iconh: 46,
        //       title: "活动营销",
        //       desc: "按周期进行平台促销活动，提升销量",
        //     },
        //     {
        //       icon: "https://mktv-in-cdn.mockuai.com/16250184562757273.png",
        //       iconw: 42,
        //       iconh: 46,
        //       title: "客户管理",
        //       desc: "粉丝运营工具SCRM，提高直播复购率",
        //     },
        //     {
        //       icon: "https://mktv-in-cdn.mockuai.com/16250184562538902.png",
        //       iconw: 46,
        //       iconh: 46,
        //       title: "数据管理",
        //       desc: "经营日报、竞争分析、销售管理",
        //     },
        //   ],
        // },
      ],
    };
  },
  methods: {
    onPanelChange(index) {
      this.currentIndex = index;
    },
    getBgStyle(item, index) {
      return {
        backgroundImage:
          this.currentIndex === index
            ? `url(${item.activeBg})`
            : `url(${item.bg})`,
      };
    },
    onMoreClick(item) {
      if (window._czc) {
        window._czc.push([
          "_trackEvent",
          "首页第二屏了解更多按钮",
          "点击",
          item.link,
        ]);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../style/common.less";

.panels-wrapper {
  box-sizing: border-box;
  height: 100%;
  padding: 0 6.097560975609756%;
}
h2.title {
  padding-top: 4.375%;
  margin: 0 auto 0.5555555555555556%;
  text-align: center;
  color: #000;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  font-size: 2.1951219512195124vw;
}
h5.subtitle {
  margin-bottom: 1.6666666666666667%;
  text-align: center;
  color: #666;
  font-size: 1.0975609756097562vw;
  font-weight: 400;
}
.panels {
  display: flex;
  align-items: center;
  // .pxToVw(height, 560, 1640);
  // justify-content: center;
  // margin: 0 9.375vw;
  .panel-item {
    box-sizing: border-box;
    transition: all 0.35s;
    position: relative;
    flex: 1;
    .pxToVw(width, 300, 1640);
    .pxToVw(height, 560, 1640);
    // height: 100%;
    .pxToVw(margin-right, 20, 1640);
    .pxToVw(border-radius, 16, 1640);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #001357;
    &:nth-child(1) {
      background-image: url(https://mktv-in-cdn.mockuai.com/16249673371487950.png);
    }
    &:nth-child(2) {
      background-image: url(https://mktv-in-cdn.mockuai.com/16237607418287684.png);
    }
    &:last-child {
      margin-right: 0;
    }
    // &:hover {
    //   background-color: #ff6600;
    // }
    &.active {
      // flex: 0 0 1120 * @indexVw;
      .pxToVw(width, 1120, 1640);
      &:nth-child(1) {
        background-image: url(https://mktv-in-cdn.mockuai.com/16286505801276166.png);
      }
      &:nth-child(2) {
        background-image: url(https://mktv-in-cdn.mockuai.com/16237606426209129.png);
      }
      &:hover {
        background-color: #001357;
      }
    }
    .role {
      position: absolute;
      .pxToVw(left, 29, 1640);
      .pxToVw(bottom, 21, 1640);
      line-height: 1.4;
      .pxToVw(font-size, 36, 1640);
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #fff;
    }
    .content {
      position: relative;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      // padding: 2.9464285714285716% 2.6785714285714284% 0;
      padding: 33 * @indexVw 30 * @indexVw 0;
      &.fade-enter-active,
      .fade-leave-active {
        transition: opacity 1s;
      }
      &.fade-enter,
      .fade-leave-to {
        opacity: 0;
      }
      .title {
        .pxToVw(margin-bottom, 35, 1640);
        line-height: 1.4;
        .pxToVw(font-size, 30, 1640);
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #fff;
      }
      .functions {
        display: flex;
        flex-wrap: wrap;
        // height: 68.3111954459203%;
        .pxToVw(height, 360, 1640);
        // overflow: hidden;
        box-sizing: border-box;
        .function-item {
          position: relative;
          z-index: 1;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          flex: 0 0 32.075471698113205%;
          margin-right: 1.8867924528301887%;
          margin-bottom: 1.8867924528301887%;

          // padding-top: 15.09433962264151%;
          // height: 9.75609756097561vw;
          // flex: 0 0 340*@indexVw;
          // .pxToVw(margin-right, 20, 1640);
          // .pxToVw(margin-bottom, 20 , 1640);
          background: rgba(255, 255, 255, 0.2);
          // border-radius: 0.416667vw;
          .pxToVw(border-radius, 8, 1640);
          border: 1px solid rgba(255, 255, 255, 0.6);
          backdrop-filter: blur(8px);
          // &::before {
          //   content: '';
          //   position: absolute;
          //   z-index: -1;
          //   top: 0;
          //   left: 0;
          //   right: 0;
          //   bottom: 0;
          //   background: inherit;
          //   filter: blur(8px);
          // }
          &:nth-child(3n) {
            margin-right: 0;
          }
          &:hover {
            background-color: rgba(255, 102, 0, 0.7);
            border: 1px solid rgba(255, 255, 255, 0.4);
          }
          .icon {
            // width: 11.764705882352942%;
            // height: 25%;
            width: 2.926829268292683vw;
            height: 2.926829268292683vw;
            margin-top: 3.529411764705882%;
            margin-bottom: 5%;
            object-fit: cover;
          }
          .title {
            margin-bottom: 0.8823529411764706%;
            font-size: 1.2195121951219512vw;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #fff;
          }
          .desc {
            // font-size: 14px;
            font-size: 0.8536585365853658vw;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #fff;
            opacity: 0.8;
          }
        }
      }
      .more {
        box-sizing: border-box;
        position: absolute;
        // right: 2.830188679245283%;
        // bottom: 4.933586337760911%;
        // width: 10.714285714285714%;
        // height: 7.590132827324478%;
        .pxToVw(right, 30, 1640);
        .pxToVw(bottom, 26, 1640);
        align-self: center;
        .pxToVw(width, 120, 1640);
        .pxToVw(height, 40, 1640);
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #fff;
        border-radius: 4px;
        color: #fff;
        .pxToVw(font-size, 14, 1640);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        &:hover {
          background-color: #fff;
          color: #333;
        }
      }
    }
  }
}

@media screen and (min-width: 1640px) {
  h2.title {
    padding-top: 63px;
    margin-bottom: 8px;
    font-size: 36px;
  }
  h5.subtitle {
    margin-bottom: 24px;
    font-size: 18px;
  }
  .panels {
    .panel-item {
      flex: 0 0 300px;
      width: 300px;
      height: 560px;
      &.active {
        flex: 1;
        // width: 1120px;
      }
      .role {
        left: 29px;
        bottom: 21px;
        font-size: 36px;
      }
      .content {
        padding: 33px 30px 0;
        .title {
          font-size: 30px;
        }
        .functions {
          height: 360px;
          .function-item {
            .icon {
              width: 48px;
              height: 48px;
            }
            .title {
              font-size: 20px;
            }
            .desc {
              font-size: 14px;
            }
          }
        }
        .more {
          bottom: 26px;
          right: 30px;
          height: 47px;
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  h2.title {
    padding-top: 36.875px;
    margin-bottom: 4.67188px;
    font-size: 21.0732px;
  }
  h5.subtitle {
    margin-bottom: 14.0469px;
    font-size: 10.5366px;
  }
  .panels {
    .panel-item {
      flex: 0 0 175.61px;
      width: 175.61px;
      height: 327.8px;
      &.active {
        flex: 0 0 655.61px;
        width: 655.61px;
      }
      .role {
        left: 16.9688px;
        bottom: 12.2812px;
        font-size: 21.0732px;
      }
      .content {
        padding: 19.3125px 17.5469px 0;
        .title {
          font-size: 17.561px;
        }
        .functions {
          height: 210.72px;
          .function-item {
            .icon {
              width: 28.0938px;
              height: 28.0938px;
            }
            .title {
              font-size: 11.7073px;
            }
            .desc {
              font-size: 10px;
            }
          }
        }
        .more {
          bottom: 15.2195px;
          right: 17.561px;
          font-size: 10px;
        }
      }
    }
  }
}
</style>
